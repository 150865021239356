import { isMobile } from 'react-device-detect';
import { axiosInitializerInstance, globalWorkers } from '../../services/http';
import {
    CURRENT_DEVICE_MOBILE,
    CURRENT_DEVICE_DESKTOP,
    COUNTRY_CODE_CA,
    MAINTENANCE_STATUS,
    MAINTENANCE_CODE,
    LANGUAGE_EN,
    EDITOR_CHOICE_SLUG,
    LIVE_GAMES_PLAYLIST_SLUG,
    NAV_GAMES_ALL,
    NAV_GAMES_SLOT_MACHINES,
    NAV_GAMES_TABLE,
    NAV_GAMES_OTHER,
    FILTER_LIVE_MOST_POPULAR,
    ONTARIO_COUNTY,
    COOKIE_NAME_TOKEN,
    COOKIE_NAME_AUTH_TYPE,
    LANGUAGE_FR,
    LANGUAGE_FI,
    CMS_SLUG_PAY_OUTS_BONUSES,
    CMS_SLUG_DISPUTE_RESOLUTION,
    CMS_SLUG_PRIVACY,
    CMS_PAGE_SLUG_TERMS,
    CMS_SLUG_RESPONSIBLE_GAMING,
    CMS_SLUG_CONTACT,
    CMS_SLUG_AML_KYC,
    CMS_SLUG_ABOUT_US
} from '../../variables';
import { isFRLocale, getLocalizedPath, includeRouterContext, getLocalizedCmsSlug } from '../../utils/locationConfig';
import { getCookie, handleValidJson, getLoadingPercent, handleRequestError, getContentGamesIds } from '@tlf-e/brand-utils';
import { hasSubcategoryCategories, liveFilters } from '../../utils/games';
import { getGamesData } from './games';
import { getUserInformation } from './user';
import router from 'next/router';
import { getAllowedLocales } from '../../utils/localeLanguages';

const { bootstrapRequest, detectClientIp, cmsPageRequest, wpPageRequest } = globalWorkers;

const GET_BOOTSTRAP = 'GET_BOOTSTRAP';
const GET_BOOTSTRAP_SUCCESS = 'GET_BOOTSTRAP_SUCCESS';
const GET_BOOTSTRAP_FAILED = 'GET_BOOTSTRAP_FAILED';
const GET_CURRENT_CMS_PAGE = 'GET_CURRENT_CMS_PAGE';
const GET_CURRENT_CMS_PAGE_SUCCESS = 'GET_CURRENT_CMS_PAGE_SUCCESS';
const GET_CURRENT_CMS_PAGE_FAILED = 'GET_CURRENT_CMS_PAGE_FAILED';
const GET_CASINO_GUIDE = 'GET_CASINO_GUIDE';
const GET_CASINO_GUIDE_LOADING = 'GET_CASINO_GUIDE_LOADING';
const DETECT_CLIENT_IP = 'DETECT_CLIENT_IP';
const SET_COOKIE_ACCEPTED = 'SET_COOKIE_ACCEPTED';
const SET_LOADING_PERCENT = 'SET_LOADING_PERCENT';
const SET_LOCALIZED_PATHS = 'SET_LOCALIZED_PATHS';
const STOP_SESSION_MODAL = 'STOP_SESSION_MODAL';
const SET_TOKEN_TIME = 'SET_TOKEN_TIME';
const SET_OPEN_REALITY_CHECK_MODAL = 'SET_OPEN_REALITY_CHECK_MODAL';
const SET_VIDEO_AUTOPLAY_ENABLED = 'SET_VIDEO_AUTOPLAY_ENABLED';
const SET_SEARCH_MODAL_OPEN = 'SET_SEARCH_MODAL_OPEN';
const SET_NOTIFICATION_MODAL_OPEN = 'SET_NOTIFICATION_MODAL_OPEN';
const SET_GAME_OVERLAY_MINIMIZED = 'SET_GAME_OVERLAY_MINIMIZED';
const SET_GAME_OVERLAY_ACTIVE = 'SET_GAME_OVERLAY_ACTIVE';
const SET_GENERAL_ERROR_MODAL = 'SET_GENERAL_ERROR_MODAL';
const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
const SET_MINIMIZED_GAME_EXPANDED = 'SET_MINIMIZED_GAME_EXPANDED';

const initLiveGamesLists = Object.keys({ ...liveFilters }).map((item) => {
    return { slug: item, games: [], order: [] };
});

// Based by player location, we should call different CMS slugs
const listOfLicensedSlugs = [
    CMS_SLUG_AML_KYC,
    CMS_SLUG_CONTACT,
    CMS_SLUG_RESPONSIBLE_GAMING,
    CMS_PAGE_SLUG_TERMS,
    CMS_SLUG_PRIVACY,
    CMS_SLUG_DISPUTE_RESOLUTION,
    CMS_SLUG_PAY_OUTS_BONUSES,
    CMS_SLUG_ABOUT_US
];

export const initPlaylist = {
    id: '',
    name: '',
    slug: '',
    parent_category: '',
    description: '',
    display_on_lobby: false,
    display_on_casino: false,
    boxShadowColor: '',
    backgroundColor: '',
    games: [],
    unlogged_games: []
};

export const existingContentLocales = [LANGUAGE_FR, LANGUAGE_EN, LANGUAGE_FI];

export const initialState = {
    currentDevice: isMobile ? CURRENT_DEVICE_MOBILE : CURRENT_DEVICE_DESKTOP,
    data: {
        translations: {},
        languages: [],
        cmsPages: [],
        allowedCountries: [],
        currencies: [],
        playlists: [],
        gameMostPlayed: [],
        editorChoiceGames: {
            [NAV_GAMES_ALL]: [],
            [NAV_GAMES_SLOT_MACHINES]: [],
            [NAV_GAMES_TABLE]: [],
            [NAV_GAMES_OTHER]: []
        },
        promotions: [],
        liveGamesLists: initLiveGamesLists,
        firstGameImageLink: ''
    },
    isBootstrapLoading: false,
    currentCmsPage: null,
    isCurrentCmsPageLoading: false,
    isCurrentCmsPageFailed: false,
    clientIp: {},
    cookieAccepted: false,
    paths: getLocalizedPath(LANGUAGE_EN),
    isModalStopSessionOpen: false,
    loadingProgress: 25, //define start progress value on init loading
    tokenTime: null,
    onlineRoulettePage: [],
    casinoGuide: [],
    isCasinoGuideLoading: false,
    isCasinoGuideLoaded: false,
    isOpenRealityCheckModal: false,
    videoAutoplayEnabled: false,
    isSearchModalOpened: false,
    isNotificationModalOpened: false,
    isGameOverlayMinimized: null,
    isGameOverlayActive: false,
    generalErrorModal: false,
    desktopHeaderHeight: 0,
    isMinimizedGameExpanded: false,
    licenseData: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_BOOTSTRAP:
        return {
            ...state,
            isBootstrapLoading: true
        };
    case GET_BOOTSTRAP_SUCCESS:
        return {
            ...state,
            data: action.payload,
            isBootstrapLoading: false
        };
    case GET_BOOTSTRAP_FAILED:
        return {
            ...state,
            isBootstrapLoading: false
        };
    case GET_CURRENT_CMS_PAGE:
        return {
            ...state,
            isCurrentCmsPageLoading: true
        };
    case GET_CURRENT_CMS_PAGE_SUCCESS:
        const slug = action.slug;
        return {
            ...state,
            currentCmsPage: { ...state.currentCmsPage, [slug]: action.payload.data },
            isCurrentCmsPageLoading: false,
            isCurrentCmsPageFailed: false
        };
    case GET_CURRENT_CMS_PAGE_FAILED:
        return {
            ...state,
            isCurrentCmsPageLoading: false,
            isCurrentCmsPageFailed: true
        };
    case GET_CASINO_GUIDE:
        return {
            ...state,
            casinoGuide: action.payload,
            isCasinoGuideLoading: false,
            isCasinoGuideLoaded: true
        };
    case GET_CASINO_GUIDE_LOADING:
        return {
            ...state,
            isCasinoGuideLoading: true
        };
    case DETECT_CLIENT_IP:
        return {
            ...state,
            clientIp: action.payload,
            licenseData: getLocalizedCmsSlug(action.payload.loc)
        };
    case SET_COOKIE_ACCEPTED:
        return {
            ...state,
            cookieAccepted: action.payload
        };
    case SET_LOADING_PERCENT:
        const currentProgress = state.loadingProgress + action.payload;
        const loadingProgress = currentProgress > 100 ? 100 : currentProgress;
        return {
            ...state,
            loadingProgress
        };
    case SET_LOCALIZED_PATHS:
        return {
            ...state,
            paths: getLocalizedPath(action.payload)
        };
    case STOP_SESSION_MODAL:
        return {
            ...state,
            isModalStopSessionOpen: action.payload
        };
    case SET_TOKEN_TIME:
        return {
            ...state,
            tokenTime: action.payload
        };
    case SET_OPEN_REALITY_CHECK_MODAL:
        return {
            ...state,
            isOpenRealityCheckModal: action.payload
        };
    case SET_VIDEO_AUTOPLAY_ENABLED:
        return {
            ...state,
            videoAutoplayEnabled: action.payload
        };
    case SET_SEARCH_MODAL_OPEN:
        return {
            ...state,
            isSearchModalOpened: action.payload
        };
    case SET_NOTIFICATION_MODAL_OPEN:
        return {
            ...state,
            isNotificationModalOpened: action.payload
        };
    case SET_GAME_OVERLAY_MINIMIZED:
        return {
            ...state,
            isGameOverlayMinimized: action.payload
        };
    case SET_GAME_OVERLAY_ACTIVE:
        return {
            ...state,
            isGameOverlayActive: action.payload
        };
    case SET_GENERAL_ERROR_MODAL:
        return {
            ...state,
            generalErrorModal: action.payload
        };
    case SET_HEADER_HEIGHT:
        return {
            ...state,
            desktopHeaderHeight: action.payload
        };
    case SET_MINIMIZED_GAME_EXPANDED:
        return {
            ...state,
            isMinimizedGameExpanded: action.payload
        };
    default:
        return state;
    }
};

export const getBoostrap = (payload, ip = '', country = '') => {
    const loading = () => ({
        type: GET_BOOTSTRAP
    });
    const success = (payload) => ({
        type: GET_BOOTSTRAP_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_BOOTSTRAP_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        bootstrapRequest(payload, {
            tag: 'bootstrap_web'
        })
            .then((res) => {
                const { data } = res.data;
                if (data) {
                    const { maintenanceWhitelistIps, status, allowed_countries, currencies } =
                        data['application-information'];
                    const liveGamesContent = data['cms-pages'][payload].find(
                        (item) => item.slug === LIVE_GAMES_PLAYLIST_SLUG
                    )?.content;
                    const isMaintenance =
                        status === MAINTENANCE_STATUS && maintenanceWhitelistIps.every((item) => item !== ip);
                    if (isMaintenance) {
                        return includeRouterContext(handleRequestError, {
                            status: MAINTENANCE_CODE,
                            failedUrl: `${res.config.baseURL}${res.config.url}`
                        });
                    }
                    dispatch(setLoadingPercent(getLoadingPercent(!!res.config.headers['Authorization'], 'bootstrap')));
                    const liveGamesLists = handleValidJson(liveGamesContent)
                        ? JSON.parse(liveGamesContent)
                        : initLiveGamesLists;

                    dispatch(
                        success({
                            translations: data.translations[payload],
                            languages: getAllowedLocales(country),
                            playlists: Array.isArray(data.playlists)
                                ? data.playlists
                                    .map((item) => ({ ...initPlaylist, ...item }))
                                    .filter((item) => hasSubcategoryCategories.includes(item.parent_category))
                                : [],
                            cmsPages: data['cms-pages'][payload],
                            allowedCountries: allowed_countries,
                            currencies,
                            gameMostPlayed: data['game-most-played'],
                            editorChoiceGames: {
                                [NAV_GAMES_ALL]: getContentGamesIds(data['cms-pages'][payload], NAV_GAMES_ALL),
                                [NAV_GAMES_SLOT_MACHINES]: getContentGamesIds(
                                    data['cms-pages'][payload],
                                    EDITOR_CHOICE_SLUG
                                ),
                                [NAV_GAMES_TABLE]: getContentGamesIds(data['cms-pages'][payload], NAV_GAMES_TABLE),
                                [NAV_GAMES_OTHER]: getContentGamesIds(data['cms-pages'][payload], NAV_GAMES_OTHER)
                            },
                            liveGamesLists,
                            firstGameImageLink:
                                liveGamesLists.find((item) => item.slug === FILTER_LIVE_MOST_POPULAR)?.firstImage || ''
                        })
                    );
                } else throw { emptyData: true };
            })
            .catch((err) => {
                includeRouterContext(handleRequestError, err);
                dispatch(fail());
            });
    };
};

export const getCurrentCmsPage = (lang, slug) => {
    const loading = () => ({
        type: GET_CURRENT_CMS_PAGE
    });
    const success = (payload, slug) => ({
        type: GET_CURRENT_CMS_PAGE_SUCCESS,
        payload,
        slug
    });
    const fail = () => ({
        type: GET_CURRENT_CMS_PAGE_FAILED
    });
    return (dispatch, getState) => {
        const { global } = getState();
        const isLicensedSlug = listOfLicensedSlugs.includes(slug);
        const localizedSlug = isLicensedSlug ? `${slug}${global.licenseData.slugPrefix}` : slug;
        dispatch(loading());
        cmsPageRequest(lang, localizedSlug)
            .then((res) => dispatch(success(res.data, slug)))
            .catch(() => dispatch(fail()));
    };
};

export const getWpPage = (lang, domain) => {
    const loading = () => ({
        type: GET_CASINO_GUIDE_LOADING
    });
    const success = (payload) => ({
        type: GET_CASINO_GUIDE,
        payload
    });
    return (dispatch) => {
        dispatch(loading());
        wpPageRequest(lang, domain)
            .then((res) => res.text())
            .then((res) => {
                const jsonData = JSON.parse(res);
                dispatch(success(jsonData));
            })
            .catch((err) => {
                console.error(`The error occurred on Cloudflare request: ${err}`);
            });
    };
};

export const getGlobalInformation = (payload) => {
    const success = (payload) => ({
        type: DETECT_CLIENT_IP,
        payload
    });
    return (dispatch, getState) =>
        detectClientIp()
            .then((res) => res.text())
            .then((res) => {
                const jsonData = JSON.parse(res);
                const forbiddenCounty = jsonData.county === ONTARIO_COUNTY;
                axiosInitializerInstance.playerRealIp = jsonData.ip;
                dispatch(
                    success({
                        ip: jsonData.ip,
                        loc: jsonData.code_country,
                        date: jsonData.timestamp,
                        forbiddenCounty,
                        time: jsonData.timestamp * 1000,
                        isCA: jsonData.code_country === COUNTRY_CODE_CA,
                        isFRLoc: isFRLocale(jsonData.code_country)
                    })
                );

                if (forbiddenCounty) {
                    router.push('/error?code=403');
                } else {
                    const currentDevice = getState().global.currentDevice;
                    dispatch(getBoostrap(payload, jsonData.ip, jsonData.code_country));
                    dispatch(getGamesData(currentDevice));
                    //read authType from landing page
                    dispatch(getUserInformation(getCookie(COOKIE_NAME_TOKEN), payload, getCookie(COOKIE_NAME_AUTH_TYPE)));
                }
            })
            .catch((err) => {
                const status = err?.status || 500;
                router.push(`/error?code=${status}`);
                console.error(`The error occurred on Cloudflare request: ${err}`);
            });
};
export const setCookieAccepted = (payload) => ({
    type: SET_COOKIE_ACCEPTED,
    payload
});

export const setLoadingPercent = (payload) => ({
    type: SET_LOADING_PERCENT,
    payload
});

export const setLocalizedPaths = (payload) => ({
    type: SET_LOCALIZED_PATHS,
    payload
});

export const setModalStopSessionOpen = (payload) => ({
    type: STOP_SESSION_MODAL,
    payload
});

export const setTokenTime = (payload) => ({
    type: SET_TOKEN_TIME,
    payload
});

export const setOpenRealityCheckModal = (payload) => ({
    type: SET_OPEN_REALITY_CHECK_MODAL,
    payload
});

export const setVideoAutoplayEnabled = (payload) => ({
    type: SET_VIDEO_AUTOPLAY_ENABLED,
    payload
});

export const setSearchModal = (payload) => ({
    type: SET_SEARCH_MODAL_OPEN,
    payload
});

export const setNotificationModal = (payload) => ({
    type: SET_NOTIFICATION_MODAL_OPEN,
    payload
});

export const setGameOverlayMinimized = (payload) => ({
    type: SET_GAME_OVERLAY_MINIMIZED,
    payload
});

export const setGameOverlayActive = (payload) => ({
    type: SET_GAME_OVERLAY_ACTIVE,
    payload
});

export const setGeneralErrorModal = (payload) => ({
    type: SET_GENERAL_ERROR_MODAL,
    payload
});

export const setDesktopHeaderHeight = (payload) => ({
    type: SET_HEADER_HEIGHT,
    payload
});

export const setMinimizedGameExpanded = (payload) => ({
    type: SET_MINIMIZED_GAME_EXPANDED,
    payload
});
